$background-color: #f2f3f5;
$placeholder-color: #aaa;

$color-dark: #222;
$color-dark-2: #666;
$color-gray: #e3e3e3;
$color-yellow: #fdf4db;
$color-cyan: #66afe9;
$color-white: #fff;
$color-red: #f03434;
$color-chartreuse: #8CFF0D;
$color-light-green: #97f88a;
$color-blue: #6c64e2;
$color-persian-indigo: #371982;
