.dark {
  color: $color-dark;
}

.persian-indigo {
  color: $color-persian-indigo;
}

.white {
  color: $color-white;
}

.bg-white {
  background-color: $color-white;
}

.bg-blue {
  background-color: $color-blue;
}

.bg-gray {
  background-color: $color-gray;
}

.bg-red {
  background-color: $color-red;
}

.bg-persian-indigo {
  background-color: $color-persian-indigo;
}

.bg-chartreuse {
  background-color: $color-chartreuse;
}

.bg-light-green {
  background-color: $color-light-green;
}